<template>
  <section class="container is-fullhd">
    <div v-if="!notFound">
      <div id="content">
        <GoBack class="back--btn icon-circle" />

        <div class="columns is-desktop" :class="{ 'mt-4': !isMobileView }">
          <div class="column is-8" ref="description">
            <div>
              <b-carousel :autoplay="false" :class="{ 'mb-6': !isMobileView }">
                <b-carousel-item
                  v-for="(item, i) in tourData.galleryImages"
                  :key="i"
                >
                  <!-- <section class="is-flex mb-3 tour-details">
                    <div
                      class="is-flex tag mr-2"
                      v-if="tourData.reviews && tourData.reviews.length"
                    >
                      <b-icon icon="star" class="mr-1 star-icon"></b-icon
                      ><b>{{ overallRating }}</b>
                    </div>
                  </section> -->

                  <a class="image">
                    <img class="rounded-image" :src="item" />
                  </a>
                </b-carousel-item>

                <span
                  v-if="gallery"
                  @click="switchGallery(true)"
                  class="modal-close is-large"
                />
              </b-carousel>
            </div>

            <div
              class="is-flex is-align-items-center activity--duration mb-3 mt-5"
              v-if="tourData.city"
            >
              <img src="../assets/location-icon.svg" class="mr-1 icon" />
              <span class="duration">{{ tourData.city }}</span>
            </div>

            <h1 class="title mb-2 is-size-5">
              {{ tourData.title }}
            </h1>

            <div
              class="mt-2"
              v-if="tourData.reviews && tourData.reviews.length"
            >
              <b-rate
                class="block mb-3"
                v-model="overallRating"
                disabled
              ></b-rate>
            </div>
            <!-- <div class="about-section">
              <b-tabs
                v-model="activeTab"
              >
                <b-tab-item label="Details">
                  <div class="columns">
                    <div class="column">
                      <p class="subtitle">Duration</p>
                    </div>
                    <div
                      class="column"
                      :class="{
                        'is-flex is-justify-content-end': !isMobileView,
                      }"
                    >
                      <p class="description">
                        {{ getHoursAndMinutes(tourData.duration) }}
                      </p>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <p class="has-text-weight-bold">Available Languages</p>
                    </div>
                    <div
                      class="column"
                      :class="{
                        'is-flex is-justify-content-end': !isMobileView,
                      }"
                    >
                      <p class="description">
                        English, Portuguese, Spanish, French
                      </p>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <p class="subtitle">Digital Ticket</p>
                    </div>
                    <div
                      class="column"
                      :class="{
                        'is-flex is-justify-content-end': !isMobileView,
                      }"
                    >
                      <p class="description">
                        Print your ticket or show it to your guide
                      </p>
                    </div>
                  </div>

                  <div v-if="tourData.whatsIncluded.length" class="mb-5">
                    <p class="mb-4 subtitle has-text-weight-bold">
                      What's included
                    </p>

                    <span class="description" v-html="tourData.whatsIncluded">
                      {{ tourData.whatsIncluded }}</span
                    >
                  </div>

                  <div
                    v-for="(collapse, index) of collapsableContent"
                    :key="index"
                    class="mb-5"
                  >
                    <section class="mb-4" v-if="collapse.text">
                      <p class="subtitle mb-3">
                        {{ collapse.title }}
                      </p>

                      <div
                        class="description"
                        v-if="Array.isArray(collapse.text)"
                      >
                        <div v-for="(item, i) in collapse.text" :key="i">
                          <div class="is-flex is-align-items-center">
                            <img
                              src="../assets/check--green.svg"
                              class="mr-3 icon"
                            />
                            {{ item }}
                          </div>
                        </div>
                      </div>

                      <span v-else class="description" v-html="collapse.text">
                        {{ collapse.text }}</span
                      >
                    </section>
                  </div>

                  <div class="mt-6">
                    <GmapMap
                      style="width: auto; height: 350px"
                      :zoom="19"
                      :center="{
                        lat: 38.71364974975586,
                        lng: -9.138160705566406,
                      }"
                    >
                      <GmapMarker
                        :position="{
                          lat: 38.71364974975586,

                          lng: -9.138160705566406,
                        }"
                      />
                    </GmapMap>

                    <div class="map-container">
                      <p class="has-text-weight-bold subtitle mt-3 mb-2">
                        Meeting point:
                      </p>

                      <div class="is-flex">
                        <div class="is-flex is-align-items-center">
                          <a
                            class="link"
                            href="https://maps.google.com/?q=Pra%C3%A7a+da+Figueira,+1100-241+Lisboa,+Portugal&ftid=0xd1933877cc93d37:0x85fbf2cb1b9b558e"
                            target="_blank"
                            >{{ tourData.meetingPoint }}</a
                          >
                        </div>

                        <div @click="copyToClipboard(tourData.meetingPoint)">
                          <img
                            src="../assets/copy.svg"
                            class="icon ml-2 mt-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab-item>

                <b-tab-item label="Description">
                  <p
                    v-if="!showMore"
                    class="description"
                    v-html="tourData.description"
                  >
                    {{ tourData.description.substring(0, maxCharsToShow) }}

                    <span @click="toggleShowMore" class="more"
                      >...Show more</span
                    >
                  </p>

                  <p
                    v-else
                    class="description-text"
                    v-html="tourData.description"
                  >
                    {{ tourData.description }}

                    <span @click="toggleShowMore" class="more">Show less</span>
                  </p>
                </b-tab-item>

                <b-tab-item label="Itinerary">
                  <div class="rightbox">
                    <div class="rb-container">
                      <ul class="rb">
                        <li
                          class="rb-item"
                          ng-repeat="itembx"
                          v-for="(stop, index) in tourData.stops"
                          :key="index"
                        >
                          <div class="item-title mb-2">{{ stop.title }}</div>
                          <img
                            :src="stop.imageUrl"
                            v-if="stop.imageUrl"
                            class="mt-3 mb-3 stop--image"
                          />
                          <div class="item-subtitle">
                            {{ stop.description }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </b-tab-item>
              </b-tabs>
            </div> -->

            <p
                    v-if="!showMore"
                    class="description mb-5 mt-2"
                    v-html="tourData.description"
                  >
                    {{ tourData.description.substring(0, maxCharsToShow) }}

                    <span @click="toggleShowMore" class="more"
                      >...Show more</span
                    >
                  </p>

                  <p
                    v-else
                    class="description-text"
                    v-html="tourData.description"
                  >
                    {{ tourData.description }}

                    <span @click="toggleShowMore" class="more">Show less</span>
                  </p>

            <div class="columns">
              <div class="column">
                <p class="subtitle">Duration</p>
              </div>
              <div
                class="column"
                :class="{
                  'is-flex is-justify-content-end': !isMobileView,
                }"
              >
                <p class="description">
                  {{ getHoursAndMinutes(tourData.duration) }}
                </p>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <p class="has-text-weight-bold">Available Languages</p>
              </div>
              <div
                class="column"
                :class="{
                  'is-flex is-justify-content-end': !isMobileView,
                }"
              >
                <p class="description">
                  English, Portuguese, Spanish, French
                </p>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <p class="subtitle">Digital Ticket</p>
              </div>
              <div
                class="column"
                :class="{
                  'is-flex is-justify-content-end': !isMobileView,
                }"
              >
                <p class="description">
                  Print your ticket or show it to your guide
                </p>
              </div>
            </div>

            <div v-if="tourData.whatsIncluded.length" class="mb-5">
              <p class="mb-4 subtitle has-text-weight-bold">
                What's included
              </p>

              <span class="description" v-html="tourData.whatsIncluded">
                {{ tourData.whatsIncluded }}</span
              >
            </div>

            <div
              v-for="(collapse, index) of collapsableContent"
              :key="index"
              class="mb-5"
            >
              <section class="mb-4" v-if="collapse.text">
                <p class="subtitle mb-3">
                  {{ collapse.title }}
                </p>

                <div
                  class="description"
                  v-if="Array.isArray(collapse.text)"
                >
                  <div v-for="(item, i) in collapse.text" :key="i">
                    <div class="is-flex is-align-items-center">
                      <img
                        src="../assets/check--green.svg"
                        class="mr-3 icon"
                      />
                      {{ item }}
                    </div>
                  </div>
                </div>

                <span v-else class="description" v-html="collapse.text">
                  {{ collapse.text }}</span
                >
              </section>
            </div>

            <div class="mt-6">
              <GmapMap
                style="width: auto; height: 350px"
                :zoom="19"
                :center="{
                  lat: 38.71364974975586,
                  lng: -9.138160705566406,
                }"
              >
                <GmapMarker
                  :position="{
                    lat: 38.71364974975586,

                    lng: -9.138160705566406,
                  }"
                />
              </GmapMap>

              <div class="map-container">
                <p class="has-text-weight-bold subtitle mt-3 mb-2">
                  Meeting point:
                </p>

                <div class="is-flex">
                  <div class="is-flex is-align-items-center">
                    <a
                      class="link"
                      href="https://maps.google.com/?q=Pra%C3%A7a+da+Figueira,+1100-241+Lisboa,+Portugal&ftid=0xd1933877cc93d37:0x85fbf2cb1b9b558e"
                      target="_blank"
                      >{{ tourData.meetingPoint }}</a
                    >
                  </div>

                  <div @click="copyToClipboard(tourData.meetingPoint)">
                    <img
                      src="../assets/copy.svg"
                      class="icon ml-2 mt-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          
            <p class="subtitle mt-5 mb-6">Itinerary</p>
            <div class="rightbox">
              <div class="rb-container">
                <ul class="rb">
                  <li
                    class="rb-item"
                    ng-repeat="itembx"
                    v-for="(stop, index) in tourData.stops"
                    :key="index"
                  >
                    <div class="item-title mb-2">{{ stop.title }}</div>
                    <img
                      :src="stop.imageUrl"
                      v-if="stop.imageUrl"
                      class="mt-3 mb-3 stop--image"
                    />
                    <div class="item-subtitle">
                      {{ stop.description }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="similarActivities.length" class="mb-3 mt-6">
              <p class="card-header-title subtitle mt-4 mb-5 is-size-5">
                You might also like
              </p>
              <ActivitiesContainer
                :showMore="false"
                class="mt-3"
                :is-full-width="false"
                :is-experiences-ids="false"
                :Experiences="similarActivities"
                :Tickets="[]"
              />
            </div>
            <div class="info--container mb-3 is-flex is-align-items-flex-start">
              <vue-lottie
                class="lottie-an mr-4"
                :options="lottieOptions"
                width="100%"
                height="100%"
                style="margin: 0"
              />
              <p class="text">
                Can't find the tour you want?
                <a class="link" href="mailto:support@guiders.pt">Email us</a> or
                <a
                  class="link"
                  href="https://wa.me/351920043375"
                  target="_blank"
                  >send a WhatsApp message</a
                >
                telling us where you'd like to visit in Portugal and your group
                size. We'll plan the tour and send you a price quote!
              </p>
            </div>

            <HpFAQ
              class="faq"
              :prop-questions="Questions"
              :is-questions-opened="false"
              is-full-width
            />
          </div>

          <div
            class="column book-column is-hidden-touch"
            :style="{ height: infoHeight + 'px' }"
          >
            <div class="booking-container">
              <booking-modal
                v-if="tourData.price"
                :isBookingModalActive="isBookingModalActive"
                :tourData="tourData"
                :fullName="fullName"
                :email="email"
                :phoneNumber="phoneNumber"
              >
              </booking-modal>
              <FreeBookingModal
                v-if="!tourData.price"
                :isBookingModalActive="isBookingModalActive"
                :tourData="tourData"
                :fullName="fullName"
                :email="email"
              />
              <div class="is-flex is-items-align-center mt-5">
                <img src="../assets/refund.svg" class="mr-2 icon--md" />Cancel
                up to 48 hours prior to the start of your experience for a full
                refund
              </div>
            </div>
          </div>
        </div>

        <footer class="page-footer is-hidden-desktop">
          <div class="row footer-mobile">
            <!--  <b-button class="is-large" type="is-primary" expanded rounded @click="isBookingModalActive = true"> {{
              !isEvent ? `Book now - ${tourData.price}` : `Reserve - ${eventData.price}` }}€</b-button> 

        <b-button class="is-large" type="is-primary" expanded rounded @click="isBookingModalActive = true"> 
                Book now for {{ tourData.price}} €</b-button>  -->

            <div
              class="columns is-mobile"
              :class="{ 'mt-1 mb-1': !tourData.price }"
            >
              <div
                class="column"
                :class="{ 'is-5': !tourData.price, 'is-7': tourData.price }"
              >
                from
                <span class="price">
                  {{
                    !tourData.isPrivateOnly
                      ? tourData.price
                      : tourData.privatePrice
                  }}€
                </span>
                <p class="label mt-1 mr-2" v-if="tourData.price">
                  Reserve your tour with a small deposit.
                </p>
              </div>
              <b-button
                class="is-large mr-3"
                type="is-primary"
                icon-right="arrow-right"
                expanded
                rounded
                @click="isBookingModalActive = true"
              >
                {{ tourData.price ? "Book now" : "Reserve your spot" }}
              </b-button>

              <!--  <b-button class="is-large" type="is-primary" expanded rounded @click="isBookingModalActive = true"> {{
      !isEvent ? `Book now - ${tourData.price}` : `Book now - ${eventData.price}` }}€</b-button> -->
            </div>
          </div>
        </footer>

        <!-- <footer class="page-footer is-hidden-desktop">
          <div class="row footer-mobile">

            <div class="columns is-mobile">
              <div class="column is-3 pr-1">
                <label>from</label>
                <p class="has-text-weight-bold">{{
                  !isEvent ? `${tourData.price}` : `${eventData.price}` }}€</p>
              </div>

              <div class="column pl-1">
                <b-button class="is-large" type="is-primary" expanded rounded @click="isBookingModalActive = true">
                  <span class="mr-4">Book now </span>
                <img src="../assets/arrow-diagonal.svg"/>
                </b-button>
              </div>
            </div>


          </div>
        </footer> -->
      </div>
    </div>

    <div v-else>
      <not-found />
    </div>

    <b-modal
      :class="{ 'is-modal-mobile': isMobileView }"
      v-model="isBookingModalActive"
      scroll="keep"
    >
      <booking-modal
        v-if="tourData.price"
        :isBookingModalActive="isBookingModalActive"
        :tourData="tourData"
        :fullName="fullName"
        :email="email"
        :phoneNumber="phoneNumber"
      >
      </booking-modal>
    </b-modal>
  </section>
</template>

<script>
//import Reviews from "@/components/elements/Reviews";
import { db } from "../firebase";
import { mapMutations, mapState, mapActions } from "vuex";
import NotFound from "../components/PageNotFound.vue";
import BookingModal from "./elements/BookingModal.vue";
import { HelpersMixin } from "../mixins";
import GoBack from "./elements/GoBack.vue";
import {
  getHoursAndMinutes,
  convertMinutesToHoursAndMinutes,
  addDurationToTime,
  dateWithName,
} from "../helpers";
import { ActivityCategories as Categories } from "../enums/ActivityCategories";
import {
  ActivitiesMutations,
  ActivitiesActions,
  ActivitiesStates,
  UsersStates,
} from "../store/Storetypes";
//import TicketsAndActivitiesContainer from "./TicketsAndActivitiesContainer.vue";
import ActivitiesContainer from "./ActivitiesContainer.vue";
import FreeBookingModal from "@/components/elements/FreeBookingModal";
import HpFAQ from "./HpFAQ.vue";
//import axios from "axios";
import { Tours } from "@/components/Tours";
import VueLottie from "vue-lottie";
export default {
  components: {
    VueLottie,
    NotFound,
    BookingModal,
    GoBack,
    //TicketsAndActivitiesContainer,
    ActivitiesContainer,
    HpFAQ,
    // Reviews,
    FreeBookingModal,
  },
  metaInfo() {
    return {
      title: this.TourTitle,
      titleTemplate: `Guiders |  ${this.TourTitle}`,
      content: this.TourDescription,
      keywords:
        "what to do in lisbon, tuk tuk tour, tour lisbon, experiences lisbon, guided tour lisbon, guided tuk tuk tour, guided tuk tuk tour lisbon",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.TourDescription,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: `%s |  ${this.TourTitle}`,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.TourDescription,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: this.PageUrl,
        },
      ],
      link: [{ rel: "canonical", href: this.PageUrl }],
    };
  },
  data() {
    return {
      lottieOptions: {
        animationData: require("@/assets/animations/info-an.json"),
        loop: true,
        autoplay: true,
      },
      isFixed: false,
      stickyOffset: 0,
      tourData: {},
      Tours,
      showMore: false,
      maxCharsToShow: 300,
      gallery: false,
      firstPartDescription: "",
      secondPartDescription: "",
      isDescriptionSplit: false,
      isLoading: true,
      notFound: false,
      stars: null,
      comment: "",
      isSubmitButtonValid: false,
      loggedIn: false,
      profilePhoto: this.$cookies.get("smg_photoURL"),
      isBookingModalActive: false,
      fullName: "",
      email: "",
      infoHeight: 100,
      phoneNumber: "",
      Categories,
      isOpen: -1,
      getHoursAndMinutes,
      convertMinutesToHoursAndMinutes,
      addDurationToTime,
      dateWithName,
      remainingTimesToRedeem: 0,
      couponName: undefined,
      discountPercentage: undefined,
    };
  },
  mixins: [HelpersMixin],
  mounted() {
    /*  const activityId = this.$route.params.id;
    const index = Tours.findIndex((tour) => tour.id === activityId);
    if (index >= 0 && activityId) {
      this.tourData = Tours[index];
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}/activities/details/${activityId}`)
      .then((response) => {
        if (response.data.success) {
          this.tourData = response.data.message;
          this.isLoading = false;
          this.$nextTick(() => {
            //Notify renderer that we are ready for a snapshot
            document.dispatchEvent(new Event("render-complete"));
          });
        } else {
          this.notFound = true;
        }
      }); */
    /*

    fetch(`${process.env.VUE_APP_API_URL}/activities/details/${activityId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.tourData = data.message;
          this.isLoading = false;
          document.addEventListener("DOMContentLoaded", function () {
      setTimeout(function () {
        document.dispatchEvent(new Event("rendered-page"));
      }, 1);
    });
          
        } else {
          this.notFound = true;
        }

        if (!this.tourData.isVisible) {
          this.notFound = true;
        }
      })
      .catch((error) => {
        console.log(error);
        this.notFound = true;
      }); */
    this.calculateInfoHeight();
    this.addStructuredData();
    this.stickyOffset = this.$refs.stickyDiv.offsetTop;
    window.addEventListener("scroll", this.handleScroll);
  },
  /* mounted() {
    this.calculateInfoHeight();
     const activityIndex = this.Tours.findIndex(
      (tour) => tour.id === this.$route.params.id
    );
    if (activityIndex < 0) {
      this.finishPreparingActivityData(true);
      return;
    }
    this.initialTourData = this.activities[activityIndex];
    document.addEventListener("DOMContentLoaded", function () {
      setTimeout(function () {
        document.dispatchEvent(new Event("rendered-page"));
      }, 1);
    });

    this.$nextTick(() => {
      setTimeout(() => {
        document.dispatchEvent(new Event('rendered-page'))
      }, 1000);
    }); 
  },*/
  created() {
    this.$metaInfo = {
      title: `%s |  ${this.TourTitle}`,
      meta: [
        {
          property: "og:title",
          content: `%s |  ${this.TourTitle}`,
        },
        {
          name: "description",
          content: this.TourDescription,
        },
        {
          property: "og:description",
          content: this.TourDescription,
        },
        {
          property: "og:url",
          content: this.PageUrl,
        },
      ],
      link: [{ rel: "canonical", href: this.PageUrl }],
    };
    this.getActivities();
    window.scrollTo(0, 0);
    const activityId = this.$route.params.id;

    if (activityId) {
      //  this.getActivityData(activityId);
      this.prepareActivityData(activityId);
    }

    this.fullName = this.user?.displayName || "";
    this.email = this.user?.email || "";
    if (!this.$route.params.title)
      this.$router.push({
        params: { title: this.slugify(this.tourData.title) },
      });
  },
  computed: {
    ...mapState({
      selectedActivityId: (state) =>
        state.activities[ActivitiesStates.SELECTED_ACTIVITY_ID],
      user: (state) => state.users[UsersStates.USER],
      selectedActivityData: (state) =>
        state.activities[ActivitiesStates.SELECTED_ACTIVITY_DATA],
      activities: (state) => state.activities[ActivitiesStates.ACTIVITIES],
    }),
    Questions() {
      return [
        {
          title: "Booking confirmation",
          text: `After paying for your booking, you'll receive an email, to the email you provided, with your booking details. If you didn't receive an email please contact us at <a class='link' href='mailto:support@guiders.pt'>support@guiders.pt</a> or message us at <a class='link' href='https://wa.me/351920043375'>+351 920 043 375</a>`,
        },
        {
          title: "I'm running late to my activity. What do I do?",
          text: "If you are running late to your activity please let us know by emailing us at <a class='link' href='mailto:support@guiders.pt'>support@guiders.pt</a> or message us at <a class='link' href='https://wa.me/351920043375'>+351 920 043 375</a>.",
        },
        {
          title: "I want to cancel my activity",
          text: "If you want to cancel your activity email us at <a class='link' href='mailto:support@guiders.pt'>support@guiders.pt</a> or message us at <a class='link' href='https://wa.me/351920043375'>+351 920 043 375</a>.",
        },
        {
          title: "Cancellations and refunds",
          text: `If you cancel your booking within the cancellation policy, your refund will be processed via the same payment method you used to book the activity.
  
    If your booking doesn't go as planned or you are unhappy with your experience and would like to request a refund, please send us an email to <a class='link' 
    href='mailto:support@guiders.pt'>support@guiders.pt</a> or message us at <a class='link' 
    href='https://wa.me/351920043375'>+351 920 043 375</a> telling us the reason(s) why you'd like a refund. Refund requests are evaluated on a case-by-case basis.
  
  In the unlikely event that we need to cancel your booking, you are always entitled to a full refund. If you've received a cancellation confirmation email from us, your refund will be processed via the same payment method you used to book the activity`,
        },
      ];
    },
    ratings() {
      return this.tourData.reviews.map((obj) => obj.stars);
    },
    overallRating() {
      return this.getOverallRating(this.ratings);
    },
    TourTitle() {
      return this.tourData.title;
    },
    TourDescription() {
      return this.tourData.description;
    },
    PageUrl() {
      return `https://www.guiders.pt${this.$route.fullPath}`;
    },
    similarActivities() {
      let similarActivities = [];
      const otherActivities = this.activities.filter(
        (activity) => activity.id !== this.tourData.id
      );
      otherActivities.map((activity) => {
        if (
          activity.categories.some((category) =>
            this.tourData.categories.includes(category)
          )
        ) {
          similarActivities.push(activity);
        }
      });
      return similarActivities.slice(0, 5);
    },
    reviewData() {
      const { stars, comment } = this;
      return {
        stars,
        comment,
      };
    },
    collapsableContent() {
      if (!this.tourData) return [];
      const content = [];
      if (this.tourData.whatToExpect.length) {
        content.push({
          title: "What to expect",
          text: this.tourData.whatToExpect,
        });
      }
      if (this.tourData.additionalInfo) {
        content.push({
          title: "Additional information",
          text: this.tourData.additionalInfo,
        });
      }
      if (this.tourData.notSuitableFor) {
        content.push({
          title: "Not suitable for",
          text: this.tourData.notSuitableFor,
        });
      }
      if (this.tourData.accessibility) {
        content.push({
          title: "Accessibility",
          text: this.tourData.accessibility,
        });
      }

      return content;
    },
  },
  watch: {
    reviewData: {
      handler: function () {
        if (this.stars !== null && this.comment.length > 0) {
          this.isSubmitButtonValid = true;
        } else {
          this.isSubmitButtonValid = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getActivities: ActivitiesActions.GET_ACTIVITIES,
    }),
    ...mapMutations({
      getActivityData: ActivitiesMutations.GET_ACTIVITY_DATA,
    }),
    handleScroll() {
      if (window.pageYOffset >= this.stickyOffset) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
    addStructuredData() {
      const script = document.createElement("script");
      script.type = "application/ld+json";
      script.innerHTML = JSON.stringify({
        "@context": "https://schema.org/",
        "@type": "Product",
        name: this.TourTitle,
        image: this.tourData.coverImage,
        description: this.TourDescription,
        sku: this.tourData.id,
        //"mpn": this.product.mpn,
        brand: {
          "@type": "Brand",
          name: "Guiders.pt",
        },
        offers: {
          "@type": "Offer",
          url: this.PageUrl,
          priceCurrency: "EUR",
          price: this.tourData.price,
          priceValidUntil: "2124-12-31",
          itemCondition: "https://schema.org/NewCondition",
          availability: "https://schema.org/InStock",
          seller: {
            "@type": "Organization",
            name: "Guiders.pt",
          },
        },
      });
      document.head.appendChild(script);
    },
    calculateInfoHeight() {
      this.infoHeight = this.$refs.description.clientHeight;
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    async getCouponData(couponId) {
      await fetch(`${process.env.VUE_APP_API_URL}/stripe/coupon/details`, {
        method: "POST",
        body: JSON.stringify({ couponId }),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            const coupon = data.coupon;
            this.remainingTimesToRedeem =
              coupon.max_redemptions - coupon.times_redeemed;
            this.couponName = coupon.name;
            this.discountPercentage = coupon.percent_off;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$buefy.toast.open({
            duration: 10000,
            message: error,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    switchGallery(value) {
      this.gallery = value;
      if (value) {
        return document.documentElement.classList.add("is-clipped");
      } else {
        return document.documentElement.classList.remove("is-clipped");
      }
    },
    finishPreparingActivityData(activityFound) {
      this.isLoading = false;
      this.notFound = activityFound;
    },
    prepareActivityData(activityId) {
      console.log({ activities: this.activities });
      const selectedActivityIndex = this.activities.findIndex(
        (activity) => activity.id === activityId
      );
      console.log({ selectedActivityIndex });
      if (selectedActivityIndex < 0) {
        this.notFound = true;
        return;
      }
      this.tourData = this.activities[selectedActivityIndex];
      this.isLoading = false;
      if (!this.tourData) return;
      if (this.tourData.whatsIncluded) {
        if (this.tourData.whatsIncluded.length >= 450) {
          this.isDescriptionSplit = true;

          let firstPeriod = this.tourData.whatsIncluded
            .substring(0, 425)
            .lastIndexOf(".");
          if (firstPeriod !== -1) {
            this.firstPartDescription = this.tourData.whatsIncluded.substring(
              0,
              firstPeriod + 1
            );
            this.secondPartDescription = this.tourData.whatsIncluded.substring(
              firstPeriod + 1
            );
          } else {
            this.firstPartDescription = this.tourData.whatsIncluded.substring(
              0,
              400
            );

            this.secondPartDescription =
              this.tourData.whatsIncluded.substring(400);
          }
        }
      }
      this.notFound = false;
      this.isLoading = false;

      if (!this.tourData.isVisible) {
        this.notFound = true;
      }
    },
    submitReview() {
      let reviewObject = {
        autor: this.$cookies.get("smg_uid"),
        autor_id: this.$cookies.get("smg_uid"),
        name: this.$cookies.get("smg_displayName"),
        profilePhoto: this.$cookies.get("smg_photoURL"),
        stars: this.stars,
        comment: this.comment,
      };

      let evaluations = [];
      evaluations = this.tourData.evaluations;
      evaluations.push(reviewObject); //add current review to the posted reviews array

      //calculate stars average
      let tourStars = 0;
      evaluations.map((review) => {
        tourStars += review.stars;
      });

      tourStars = tourStars / evaluations.length;
      db.firestore()
        .collection("tours")
        .doc(this.$route.query.id)
        .update({ evaluations: evaluations, stars: tourStars })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 10000,
            message:
              "There has been an error sending your review. Try again later.",
            position: "is-bottom",
            type: "is-danger",
          });
        });
      this.stars = null;
      this.comment = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
.description-text {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lottie-an {
  margin: 0;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.more {
  cursor: pointer;
  color: #0e3d4dbf;
}

.container--outlined {
  box-shadow: none;
  border-radius: 10px;
  font-weight: 600;
  padding: 15px;
  border: none;
}

.back--btn {
  z-index: 2;
  position: relative;
  top: 12px;
  display: block;
}

.link {
  color: #0e3d4d;
}

.map-container {
  padding: 15px 20px;
  border-radius: 0 0 20px 20px;
  margin-bottom: 1.5rem;
}

.danger--container {
  background-color: #ff7272;
  color: #fff;
  border-radius: 10px;
  padding: 0.5rem 0.75rem;
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  .sale--icon {
    margin-top: 3px;
    place-self: flex-start;
    height: 1rem;
    width: 1rem;
  }
}

.icon {
  height: 1.15rem;
  width: 1.15rem;
}

.icon--md {
  height: 1.75rem;
  width: 1.75rem;
}

.booking-container {
  border-radius: 10px;
  position: sticky;
  top: 102px;
}

.button.is-large {
  height: 2.8rem;
  font-size: 1rem;
}

.rounded-image {
  height: 282px;
  object-fit: cover;
}

.title-with-icon {
  .description {
    margin-left: 2.6rem;
  }
}

.title {
  font-size: 1.5rem !important;
}

.stop--image {
  height: 12rem;
  width: -webkit-fill-available;
  object-fit: cover;
  border-radius: 8px;
}

@media (min-width: 764px) {
  .stop--image {
    height: 20rem;
  }
}

@media (min-width: 1024px) {
  .stop--image {
    height: 18rem;
  }
}

.subtitle {
  font-size: 1rem;
  margin-bottom: 0;
}

.description {
  font-size: 1rem;
  color: #0e3d4deb;
  font-weight: 300;
  white-space: pre-line;
}

.tour-details {
  position: absolute;
  top: 24px;
  right: 18px;
  z-index: 2;
  .tag {
    color: #0e3d4d;
    background-color: #fff;
    padding: 4px 14px;
    border-radius: 5px;
    font-size: 15px;
  }
  .icon {
    margin-right: -6px;
    margin-left: -6px;
  }
}

.card-header {
  box-shadow: none;
}

.card-header-title,
.subtitle,
.title {
  color: #0e3d4d;
  font-weight: 600;
}

.title {
  font-family: "Merriweather", serif;
}

.card-header-title,
.card-header-icon,
.card-content {
  padding: 0;
}

.card-header-title {
  font-size: 1.05rem;
}

.languages-container {
  background-color: #f7fbff;
  padding: 12px;
  border-radius: 12px;
}

::v-deep {
  .about-section {
    .b-tabs .tabs li:not(.is-active) a:focus {
      border-bottom-color: transparent;
    }

    .tab-content {
      padding: 1.5rem 0;
    }
    .tabs a {
      background-color: transparent;
      color: #0e3d4d;
    }

    .tabs li.is-active a {
      color: #0e3d4d;
      background-color: transparent;
      border-bottom: 2px solid #0e3d4d;
      border-radius: 0;
      font-weight: 600;
    }

    .b-tabs .tabs li:not(.is-active) a:focus {
      border-bottom-color: transparent;
    }
  }

  .carousel .carousel-items {
    margin: -20px !important;
    margin-top: -68px !important;
    margin-bottom: 0.75rem !important;
  }
  .vue-map {
    border-radius: 20px 20px 0 0;
  }
  .tag:not(body).tag--purple {
    border-radius: 50px;
    color: #fff;
    background-color: #7f7eff;
  }
  .tag:not(body).is-primary.is-light {
    color: var(--primary);
  }
  .b-image-wrapper > img {
    object-fit: cover;
  }
  .hero .image img {
    display: block;
    height: 22vh;
    border-radius: 7px;
    width: 100%;
  }
  .carousel .carousel-items {
    margin: 0px;
  }
  .columns {
    align-items: start;
  }
  .row {
    display: inline-flex;
  }
  .dropdown-content {
    background-color: white;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-bottom: 0;
    padding-top: 0;
  }
  .row.footer-mobile {
    display: block; //display: flex;
    //justify-content: space-between;
  }
  footer.modal-card-foot {
    background-color: white !important;
  }
}

.collapse-trigger {
  padding: 12px 0px !important;
}

.collapse .collapse-trigger .icon {
  transform: translateY(4px);
}

.collapse-content {
  margin-top: 12px;
}

.message p {
  color: #222222;
}

.message-body {
  color: #0e3d4d;
}

.page-footer {
  bottom: -1px;
  background-color: #fff;
  border-top: 1px solid rgb(29 45 83 / 7%);
  width: 100%;
  position: fixed;
  left: 0;
  padding: 12px 20px;
  backdrop-filter: blur(5px);
  .price {
    font-weight: bold;
    font-size: 1.25rem;
  }
  .columns {
    align-items: center;
  }
  .label {
    line-height: 17px;
    color: #4a5775;
    font-weight: 400;
    padding-bottom: 0;
    font-size: 0.8rem;
  }
  .label:not(:last-child) {
    margin-bottom: 0;
  }
  .button {
    height: 3.25rem;
  }
}

.media.review {
  margin: 42px 0px 24px 0px;
}

.no-reviews {
  text-align: center;
  margin: 72px 0px;
}

.review .rate {
  margin: 8px 0px 11px 0px;
}

.card {
  border-radius: 8px;
  padding: 32px;
  margin: 16px;
}

@media (min-width: 1024px) {
  .rounded-image {
    height: 468px;
  }
}

::v-deep {
  @media (max-width: 1024px) {
    .carousel .carousel-items {
      width: 100vw;
    }
  }
}

.dropdown-item,
.dropdown .dropdown-menu .has-link a {
  margin: 0;
}

.info--container {
  background-color: #f6fbff;
  padding: 22px;
  border-radius: 17px;
}

.card .label {
  color: #7a7a7a;
  display: block;
  font-size: 1rem;
  font-weight: normal;
}

.level-item {
  align-items: end;
  justify-content: flex-end;
}

.navigate-back {
  margin-bottom: 32px;
  width: fit-content;
  cursor: pointer;
}

.margin {
  margin: 42px 0px;
}

.label {
  color: #7a7a7a;
  font-weight: 500;
  padding-bottom: 12px;
}

.card .title {
  padding-bottom: 32px;
}

.book-column {
  height: 100vh;
  margin-top: -3rem;
}

// ITINERARY
.card-content.itinerary {
  margin-top: 1rem;
}

.itinerary {
  .item-title {
    font-size: 1.05rem;
  }
}

.rb-container ul.rb li:last-child {
  padding-bottom: 0.5rem;
}

.rb-container {
  margin: 24px;
  display: block;
  position: relative;
}

.rb-container ul.rb {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.rb-container ul.rb li {
  list-style: none;
  margin: auto;
  margin-left: 0;
  min-height: 50px;
  border-left: 5px dotted #dedede;
  padding: 0 0 50px 30px;
  position: relative;
}

.rb-container ul.rb li:last-child {
  border-left: 0;
  &::before {
    left: -9px;
  }
}

.rb-container ul.rb li::before {
  position: absolute;
  left: -14px;
  top: 0;
  content: " ";
  border: 9px solid #0e3d4d;
  border-radius: 500%;
  background: #fff;
  height: 24px;
  width: 24px;
  transition: all 500ms ease-in-out;
}

.rb-container ul.rb li:hover::before {
  border-color: #0e3d4dd1;
  transition: all 1000ms ease-in-out;
}

.item-title {
  font-weight: 600;
}

.item-title,
.item-subtitle {
  color: #0e3d4d;
}

::v-deep {
  .tag:not(body) {
    padding: 1rem;
  }
}
</style>
